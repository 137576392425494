//TODO: Improve performance by running toFixed filter in controller
//TODO: Investigate why job.results gets mutated; may have something to do with foamtree
function FoamTreeController($scope, $log, $window, $document, $timeout, jobTracker) {
  var CarrotSearchFoamTree = $window.CarrotSearchFoamTree;
  var vm = this;

  vm.isObject = angular.isObject;

  var _spinner = null;

  vm.visibleGroup = null;
  vm.menuHover = false;
  vm.promiseCloseWhenOff = false;

  // weight keys ordered: last index most desired weight

  vm.summary = null;
  vm.foamTree = null;
  vm.carrotArray = null;

  vm.clearFoamTree = clearFoamTree;
  vm.hasFoamtreeData = hasFoamtreeData;

  vm.visibleGroupRatios = {};

  vm.closeGroupIfNotHover = closeGroupIfNotHover;
  vm.totalGroup = [];
  vm.numberOfSamples = 0;

  let _timeoutPromise;
  function getHeight() {
    return Math.max($document[0].documentElement.clientHeight, $window.innerHeight || 0) * 6 / 8;
  }

  this.loading = false;

  this.onResize = () => {
    let timeout;
    return function resize() {
      $window.clearTimeout(timeout);

      element.css('max-height', getHeight());

      $window.setTimeout(this.foamTree.resize, 100);
    };
  };

  this.onOrientationChange = () => {
    if (this.foamTree) {
      element.css('max-height', getHeight());

      this.foamTree.resize();
    }
  };

  this.onKeyup = (e) => {
    if (e.keyCode == 27) { // escape key maps to keycode `27`
      this.visibleGroup = null;
    }
  };

  this.createFoamtree = (sampleWeights) => {
    this.loading = true;
    clearFoamTree();

    createCarrotObject(this.job, sampleWeights, (carrotArray) => {
      $timeout(() => {
        if (carrotArray && carrotArray.length) {
          createFoamTree(this.job, 'visualization');

          this.foamTree.set({ dataObject: { groups: carrotArray } });

          $window.addEventListener('orientationchange', this.onOrientationChange);
          $window.addEventListener('resize', this.onResize);
          $window.addEventListener('keyup', this.onKeyup);
        }

        this.loading = false;
      }, 250, true);
    });
  };

  this.updateWeights = (sampleWeight) => {
    this.carrotArray.forEach((sampleObj) => {
      sampleObj.weight = this.job.results.results.samples[sampleObj.label][sampleWeight];
    });

    this.foamTree.update();
  };

  // apparently dom manipulation best here, after angular finishes
  // binding component to dom
  this.$postLink = () => {
    this.createFoamtree();
  };

  this.$onDestroy = () => {
    $window.removeEventListener('keyup', this.onKeyup);
    $window.removeEventListener('resize', this.onResize);
    $window.removeEventListener('orientationChange', this.onOrientationChange);
  };

  this.updateJob = (prop, val) => {
    this.updating = true;

    const updateProp = {};
    updateProp[prop] = val;

    jobTracker.patch(this.job, updateProp).then((updatedJob) => {
      this.job = updatedJob;
      this.updateSuccess = true;
    }).catch((err) => {
      this.updateErr = err;
      $log.error(err);
    }).finally(() => {
      this.updating = false;
    });
  };


  this.addSampleNote = (sample, val, removeIdx, ev) => {
    if (!(this.job && this.job.results)) {
      $log.error('couldn\'t find job results', this.job);
      return;
    }

    if ((removeIdx === null || removeIdx === undefined) && (val === null || val === undefined)) {
      $log.error('addSampleNote must be called with val or removeIdx');
      return;
    }

    const sampleNotes = _.assign({}, this.job.results.sampleNotes || {});

    let existing = [];

    if (sampleNotes[sample]) {
      existing = [].concat(sampleNotes[sample]);
    }

    if (removeIdx >= 0) {
      if (!existing.length) {
        return;
      }

      existing.splice(removeIdx, 1);
    } else {
      existing.push(val);
    }

    if (!existing.length && sample in sampleNotes) {
      delete sampleNotes[sample];
    } else {
      sampleNotes[sample] = existing;
    }

    return this.updateJob('results.sampleNotes', sampleNotes);
  };

  /* Public*/
  function createCarrotObject(job, sampleWeights, cb) {
    if (!job || !job.results || !Object.keys(job.results).length) {
      $log.warn('no results found', angular.copy(job));
      return cb(null);
    }

    vm.carrotArray = _buildCarrotGraph(job.results, sampleWeights);

    cb(vm.carrotArray);
  }

  var timeout = null;
  var redrawn = false;
  function createFoamTree(job, elementID) {
    if (vm.foamTree) {
      //Tears down the tree, in case clearFoamTree not called in time
      //Prevents errors due to already-attached tree
      //Prevents memory leaks
      // but this has errors...
      // vm.foamTree.dispose();
    }

    vm.foamTree = new CarrotSearchFoamTree({
      id: elementID, // what elem id binds to, set in directive
      dataObject: {
        groups: vm.carrotArray,
      },
      groupColorDecorator: function (opts, params, vars) {
        vars.groupColor = params.group.color;
        vars.labelColor = 'auto';
      },
      pixelRatio: window.devicePixelRatio || 1,
      layout: 'ordered',
      // relaxationInitializer: 'squarified',
      // Remove restriction on the minimum group diameter, so that
      // we can render as many diagram levels as possible.
      groupMinDiameter: 1,

      // Lower the minimum label font size a bit to show more labels.
      groupLabelMinFontSize: 3,

      maxGroupLevelsDrawn: 2,

      // Disable rounded corners, deeply-nested groups
      // will look much better and render faster.
      groupBorderRadius: 0,

      // Lower the parent group opacity, so that lower-level groups show through.
      parentFillOpacity: 0.5,

      // Lower the border radius a bit to fit more groups.
      groupBorderWidth: 0.5,
      groupInsetWidth: 2,
      groupSelectionOutlineWidth: 0.1,
      // maxGroupLevelsDrawn: 1,
      groupFillType: 'plain',
      // wireframeLabelDrawing: 'never',
      stacking: 'hierarchical',
      // relaxationVisible: true,
      // relaxationVisible: true,

      // Less processing time
      relaxationQualityThreshold: 10,
      // relaxationMaxDuration: 15000,
      // groupBorderWidth: 0.2,
      groupStrokeWidth: 0.1,
      // groupBorderRadius: 0,
      groupBorderWidthScaling: 0.5,
      rolloutDuration: 0,
      pullbackDuration: 0,
      // relaxationVisible: 1,
      // groupStrokeType: 'none',

      onRolloutComplete: function () {
        this.resize();
      },
      onGroupDoubleClick: function (obj) {
        if (!obj.group) {
          return;
        }

        var self = this;
        $scope.$evalAsync(function () {
          if (!obj.group.groups && !obj.group.secondary) {
            load(obj.group, self);
          }
        });
      },
      onGroupClick: function (obj) {
        if (!obj.group) {
          return;
        }

        var self = this;
        $scope.$evalAsync(function () {
          vm.promiseCloseWhenOff = false;
          vm.visibleGroup = {
            ratios: job.results.results.samples[obj.group.label],
            label: obj.group.label,
            weight: obj.group.weight,
            qc: obj.group.qc,
          };
        });
      },
    });

    function load(group, foamtree) {
      if (!group.groups && !group.loading) {
        group.groups = [];

        if (_spinner == null) {
          _spinner = makeSpinner(foamtree);
        }

        window.requestAnimationFrame(function () {
          _spinner.start(group);
        });

        vm.outputKeys.forEach(function (innerKey) {
          group.groups.push({
            label: innerKey,
            color: group.color,
            weight: Number(job.results.results.samples[group.label][innerKey]),
            secondary: true,
          });
        });

        window.requestAnimationFrame(function () {
          // We need to open the group for FoamTree to update its model
          foamtree.open({ groups: group, open: true }).then(function () {
            // spinner.stop(group);
            group.loading = false;
            _spinner.stop(group);
          });
        });
      }
    }
  }

  function clearFoamTree() {
    if (vm.foamTree) {
      _spinner = null;
      vm.carrotArray = null;
      vm.visibleGroup = null;
      vm.foamTree.set({ dataObject: { groups: [] } });
    }

  }

  function hasFoamtreeData() {
    return !!Object.keys(vm.foamTree).length;
  }

  function getStatKeys() {

  }

  var _maxFail = 0;
  var count = 0;
  var numberGroupCalled = 0;

  function _buildCarrotGraph(resultsObj, sampleWeights = 'transitions/transversions') {
    if (!resultsObj || !Object.keys(resultsObj).length) {
      return null;
    }
    // numberGroupCalled++;
    var allObjects = [];
    var isTotal;

    vm.outputKeys = [].concat(resultsObj.results.order);
    vm.badSamples = [];

    // New style
    if (resultsObj.results.qc) {
      let maxFailures = 0;

      // Our mean/median/sd are stored in objects, every other summary info
      // is a scalar
      Object.keys(resultsObj.stats).forEach((key) => {
        if (typeof resultsObj.stats[key] === 'object') {
          maxFailures++;
        }
      });

      for (const sample in resultsObj.results.samples) {
        if (sample === 'total') {
          continue;
        }

        let weight = Number(resultsObj.results.samples[sample][sampleWeights]);

        let color = '#ffffff';

        const failures = resultsObj.results.qc[sample];

        if (failures) {
          const numFailures = failures && failures.length;

          color = lerpColor(color, '#8a0202', numFailures / maxFailures);
        }

        const markedGood = resultsObj.results.markedGood && resultsObj.results.markedGood[sample];
        const markedBad = resultsObj.results.markedBad && resultsObj.results.markedBad[sample];

        const sampleObj = { weight, color, label: sample, qc: failures };

        if (!markedGood && (markedBad || failures)) {
          vm.badSamples[sample] = true;
        }

        vm.visibleGroupRatios[sample] = {};
        allObjects.push(sampleObj);
      }

      return allObjects;
    }

    // Old... Not configurable
    const _sdKey = 'transitions:transversions ratio standard deviation';
    const _meanKey = 'transitions:transversions ratio mean';
    const _weightKey = 'total transitions:transversions ratio';
    const trTvSd = resultsObj.stats[_sdKey];
    const trTvMean = resultsObj.stats[_meanKey];

    let deviantUp;
    let deviantDown;
    if (trTvSd && trTvMean) {
      deviantUp = trTvSd * 3 + trTvMean;
      deviantDown = trTvMean - trTvSd * 3;
    }

    if (sampleWeights == 'transitions/transversions') {
      sampleWeights = _weightKey;
    }

    for (const sample in resultsObj.results.samples) {

      if (sample !== 'total') {
        const weight = Number(resultsObj.results.samples[sample][sampleWeights]);
        const tsTv = Number(resultsObj.results.samples[sample][_weightKey]);
        const tObj = { weight: weight, label: sample };

        if (deviantUp !== undefined && tsTv > deviantUp) {
          tObj.color = "#a54243";
          tObj.qc = ['Tr:Tv mean >= 3SD experiment mean'];
        } else if (deviantDown !== undefined && tsTv < deviantDown) {
          tObj.color = "#428bca";
          tObj.qc = ['Tr:Tv mean <= 3SD experiment mean'];
        } else {
          tObj.color = "#fff";
        }

        const markedGood = resultsObj.results.markedGood && resultsObj.results.markedGood[sample];
        const markedBad = resultsObj.results.markedBad && resultsObj.results.markedBad[sample];

        if (!markedGood && (markedBad || tObj.failure)) {
          vm.badSamples[sample] = true;
        }

        vm.visibleGroupRatios[sample] = {};

        allObjects.push(tObj);
      }
    }
    return allObjects;
  }

  function closeGroupIfNotHover() {
    if (vm.promiseCloseWhenOff) {
      vm.visibleGroup = null;
    }
    vm.menuHover = null;
    vm.promiseCloseWhenOff = false;
  }

  // expects object to have property annotationSummary
  var _tryJson = function (maybeJson) {
    if (maybeJson && typeof maybeJson === 'object') {
      return maybeJson;
    }
    try {
      return JSON.parse(maybeJson);
    } catch (err) {
      $log.warn(err, maybeJson);
      return null;
    }
  };

  // Combine with filter in directive
  function toTitleCase(input) {
    input = input || '';
    return input.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1); });
  }

  //
  // A simple utility for starting and stopping spinner animations
  // inside groups to show that some content is loading.
  //
  function makeSpinner(foamtree) {
    // Set up a groupContentDecorator that draws the loading spinner
    foamtree.set("wireframeContentDecorationDrawing", "always");
    foamtree.set("groupContentDecoratorTriggering", "onSurfaceDirty");
    foamtree.set("groupContentDecorator", function (opts, props, vars) {
      var group = props.group;
      if (!group.loading) {
        return;
      }

      // Draw the spinner animation

      // The center of the polygon
      var cx = props.polygonCenterX;
      var cy = props.polygonCenterY;

      // Drawing context
      var ctx = props.context;

      // We'll advance the animation based on the current time
      var now = Date.now();

      // Some simple fade-in of the spinner
      var baseAlpha = 0.3;
      if (now - group.loadingStartTime < 200) {
        baseAlpha *= Math.pow((now - group.loadingStartTime) / 200, 2);
      }

      // If polygon changed, recompute the radius of the spinner
      if (props.shapeDirty || group.spinnerRadius === undefined) {
        // If group's polygon changed, recompute the radius of the inscribed polygon.
        group.spinnerRadius = CarrotSearchFoamTree.geometry.circleInPolygon(props.polygon, cx, cy) * 0.4;
      }

      // Draw the spinner
      var angle = 2 * Math.PI * (now % 1000) / 1000;
      ctx.globalAlpha = baseAlpha;
      ctx.beginPath();
      ctx.arc(cx, cy, group.spinnerRadius, angle, angle + Math.PI / 5, true);
      ctx.strokeStyle = "#428bca";
      ctx.lineWidth = group.spinnerRadius * 0.3;
      ctx.stroke();

      // Schedule the group for redrawing
      foamtree.redraw(true, group);
    });

    return {
      start: function (group) {

        group.loading = true;
        group.loadingStartTime = Date.now();

        // Initiate the spinner animation
        foamtree.redraw(true, group);
      },

      stop: function (group) {
        group.loading = false;
      }
    };
  }
}


/**
 * A linear interpolator for hexadecimal colors
 * @param {String} a
 * @param {String} b
 * @param {Number} amount
 * @example
 * // returns #7F7F7F
 * lerpColor('#000000', '#ffffff', 0.5)
 * @returns {String}
 */
function lerpColor(a, b, amount) {

  var ah = parseInt(a.replace(/#/g, ''), 16),
    ar = ah >> 16, ag = ah >> 8 & 0xff, ab = ah & 0xff,
    bh = parseInt(b.replace(/#/g, ''), 16),
    br = bh >> 16, bg = bh >> 8 & 0xff, bb = bh & 0xff,
    rr = ar + amount * (br - ar),
    rg = ag + amount * (bg - ag),
    rb = ab + amount * (bb - ab);

  return '#' + ((1 << 24) + (rr << 16) + (rg << 8) + rb | 0).toString(16).slice(1);
}

angular.module('sq.jobs.results.foamTree.component', ['sq.jobs.tracker.service'])
  .component('sqFoamTree', {
    bindings: {
      job: '<jobResource',
    },
    templateUrl: 'jobs/results/foamTree/templates/sqFoamTree.tpl.html',
    controller: FoamTreeController,
  });